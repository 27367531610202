<template>
  <nav-search
    :searchKeyword="categorys"
    @search="getAllPosts"
    @zoom="removeImage"
  />

  <div v-if="loading">
    <loading />
  </div>

  <div class="container md:mx-10 my-12 mx-auto px-4 md:px-12 absolute my-30">
    <div v-if="!isData">
      <h1 class="text-center bg-orange-button shadow-lg rounded-20 my-20">
        Data Tidak Tersedia
      </h1>
    </div>
    <div>
      <div>
        <img
          id="image"
          :src="image + image_post"
          :class="[
            zoom
              ? 'animate__animated animate__fadeInRight'
              : 'animate__animated animate__fadeOutRight',

            hide ? 'hide' : '',
          ]"
          class="show_image absolute rounded-20"
          @click="showPostImage"
          alt=""
        />
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 md:gap-4">
      <div
        class="card-post flex flex-wrap -mx-1 lg:-mx-4 my-10"
        v-for="post in posts"
        :key="post.id"
      >
        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-full lg:my-4 lg:px-4 lg:w-full">
          <!-- Article -->
          <article class="overflow-hidden rounded-lg shadow-lg">
            <a>
              <img
                alt="Placeholder"
                class="block h-auto card-photo-custome"
                :src="image + post.image"
                :data-index="post.id"
                @click="showPostImage"
              />
            </a>

            <header
              class="flex items-center justify-between leading-tight p-2 md:p-4 bg-white"
            >
              <h1 class="text-lg">
                <p class="no-underline text-black title-hide mb-3" href="#">
                  {{ post.title }}
                </p>
              </h1>
              <p class="text-grey-darker text-sm">
                {{ post.created_at }}
              </p>
            </header>

            <footer
              class="flex md:flex-col items-center justify-between leading-none p-2 md:p-4 bg-white"
            >
              <div class="flex no-underline text-black">
                <img
                  alt="Placeholder"
                  class="block rounded-full"
                  :src="image + post.image"
                  style="width: 32px; height: 32px;"
                />
                <p class="ml-2 text-sm content-hide">
                  <span v-html="post.content"></span>
                </p>
              </div>
              <router-link
                :to="'/post/' + post.slug"
                class="bg-purple-hover text-white p-4 md:my-7 no-underline text-grey-darker hover:bg-green-button rounded-full"
                href="#"
              >
                Selengkapnya
              </router-link>
            </footer>
          </article>
          <!-- END Article -->
        </div>
        <!-- END Column -->
      </div>
    </div>

    <div class="md:flex md:justify-center">
      <button
        @click="loadMore"
        class="mb-3 block mx-auto text-center w-full md:w-1/3 md:mx-1 bg-orange-button hover:bg-green-button text-white font-semibold px-12 py-3 md:text-1xl rounded-full"
        :disabled="disabled"
      >
        Muat lebih banyak
      </button>
    </div>
  </div>
</template>

<style scoped>
.card-post {
  margin-top: 70px !important;
}

button:disabled {
  cursor: not-allowed;
  background: #ccc;
}

.card-photo-custome {
  height: 350px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

.card-photo-custome:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.title-hide {
  height: 30px;
  overflow: hidden;
}

.content-hide {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show_image {
  width: 100%;
  height: 50%;
  margin-left: -50px;
  margin-top: 50px;
  z-index: 10;
}

.hide {
  display: none;
}
</style>

<script>
import axios from "axios";
import NavSearch from "../components/NavSearch.vue";
import Loading from "../components/Loading.vue";

export default {
  components: {
    NavSearch,
    Loading,
  },
  name: "Posts",
  data() {
    return {
      posts: [],
      categorys: [],
      currentPost: [],
      loading: true,
      image: process.env.VUE_APP_IMAGE_URL,
      disabled: false,
      isData: true,
      image_post: "",
      zoom: false,
      hide: true,
    };
  },
  mounted() {
    this.getAllPosts();
    this.getAllCategory();
  },
  methods: {
    async getAllPosts(value) {
      let uri = process.env.VUE_APP_API_ENDPOINT + "api/post";

      if (value) {
        uri = process.env.VUE_APP_API_ENDPOINT + `api/category/${value}`;
      }

      this.posts = [];
      this.loading = true;

      await axios
        .get(uri)
        .then((res) => {
          const { data } = res.data.data;
          const counter = data.length >= 3 ? 3 : data.length;

          if (counter != 0) {
            this.isData = true;
            for (let index = 0; index < counter; index++) {
              if (data[index].created_at != null) {
                data[index].created_at = data[index].created_at
                  .split("-")
                  .join(" ");
                data[index].created_at = data[index].created_at
                  .split("")
                  .splice(0, 10)
                  .join("");
              }
              this.currentPost.push(data[index].id);
              this.posts.push(data[index]);
            }
          } else {
            this.isData = false;
          }
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    async getAllCategory() {
      await axios
        .get(process.env.VUE_APP_API_ENDPOINT + "api/category")
        .then((res) => {
          const { data } = res.data.data;
          this.categorys = data;
        })
        .catch((err) => console.log(err));
    },

    async loadMore() {
      let uri =
        process.env.VUE_APP_API_ENDPOINT + `api/post?page=` + this.nextPage;

      await axios
        .get(uri)
        .then((res) => {
          const { data } = res.data;
          const { data: getData } = res.data.data;

          if (data.length < 4) {
            this.loadMoreData = false;
            this.disabled = true;

            return;
          }

          if (data.current_page <= data.last_page) {
            this.nextPage = data.current_page + 1;
            this.disabled = false;

            for (let index = 0; index < getData.length; index++) {
              if (getData[index].id != this.currentPost[index]) {
                if (getData[index].created_at != null) {
                  getData[index].created_at = getData[index].created_at
                    .split("-")
                    .join(" ");
                  getData[index].created_at = getData[index].created_at
                    .split("")
                    .splice(0, 10)
                    .join("");
                }

                if (this.posts.length === getData.length) {
                  this.loadMoreData = false;
                  this.disabled = true;
                }

                this.posts.push(getData[index]);
              }
            }
          } else {
            this.loadMoreData = false;
            this.disabled = true;
          }

          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    async showPostImage(event) {
      // toggle zoom image
      this.zoom = !this.zoom;
      if (this.zoom) this.hide = false;

      //filter index
      let index = event.target.getAttribute("data-index");
      let filter_photo = this.posts.filter((post) => {
        return post.id == index;
      });
      this.image_post = filter_photo[0].image;
    },

    async removeImage() {
      this.zoom = false;
      this.image_post = "";
    },
  },
};
</script>
